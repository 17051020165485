<template>
    <v-row class="centro">
      <v-col class="align-center">
        <v-data-table
          dense
          :headers="headers"
          :items="imoveis"
          item-key="id"
          height="auto"
          locale="pt-BR"
          class="elevation-10 mb-10"
          :search="search"
        >
          <template v-slot:item.id_cliente="{ item }" >
            {{ buscaCliente(item) }}
          </template>

          <template v-slot:item.id_cidade="{ item }" >
            {{ buscaCidade(item) }}
          </template>

          <template v-slot:item.id_bairro="{ item }" >
            {{ buscaBairro(item) }}
          </template>

          <template v-slot:item.tipo_negocio="{ item }" >
            {{ buscaTipoNegocio(item) }}
          </template>

          <template v-slot:item.tipo_imovel="{ item }" >
            {{ buscaTipoImovel(item) }}
          </template>

          <template v-slot:item.subtipo_imovel="{ item }" >
            {{ buscaSubTipoImovel(item) }}
          </template>

          <template v-slot:item.valor="{ item }" >
            {{ formataValor(item) }}
          </template>

          <template v-slot:item.areautil="{ item }" >
            {{ formataArea(item.areautil) }}
          </template>

          <template v-slot:item.areatotal="{ item }" >
            {{ formataArea(item.areatotal) }}
          </template>

          <template v-slot:top>
            <v-toolbar id="tb" flat>
              <svg-icon height="30px" width="30px" type="mdi" :path="icone"></svg-icon>
              <v-toolbar-title>Cadastro de Imóveis</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <svg-icon class="mt-2" type="mdi" :path="pesquisar"></svg-icon>
              <v-text-field
                v-model="search"
                label="Pesquisar"
                class="ml-1 mt-7 mr-7"
              ></v-text-field>
              <v-btn
                color="black"
                dark
                class="mb-2"
                @click="newItem()"
              >
                Novo Imóvel
              </v-btn>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">
                    Tem certeza que deseja excluir o imóvel
                    {{editedItem.descricao}}?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                      Excluir
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:no-data>
            Ainda não existem dados cadastrados
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn @click="editItem(item)" class="mr-4" icon color="black">
              <svg-icon type="mdi" :path="editar"></svg-icon>
            </v-btn>
            <v-btn @click="deleteItem(item)" class="mr-4" icon color="black">
              <svg-icon type="mdi" :path="excluir"></svg-icon>
            </v-btn>
            <v-btn @click="VisualizarCapa(item)" class="mr-4" icon color="black" :disabled="(item.pasta_arquivos == null) || (item.pasta_arquivos.trim() == '')">
              <svg-icon type="mdi" :path="tour"></svg-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
</template>
  
<script>
  import svcImoveis from "../../services/svcImoveis";
  import svcClientes from "../../services/svcClientes";
  import svcCidades from "../../services/svcCidades";
  import svcBairros from "../../services/svcBairros";
  import SvgIcon from '@jamescoyle/vue-icon';
  import { mdiPencil, mdiDelete, mdiRotate360, mdiMagnify, mdiHomeCity } from '@mdi/js';
  import { decriptar, encriptar } from "../../config/cripto";
  import { descTipoNegocio, descTipoImovel, descSubTipoImovel } from "../../config/funcoes"
  import "../../config/consts";
  
  export default {
    components: {
          SvgIcon
      },
    data() {
      return {
        editar: mdiPencil,
        excluir: mdiDelete,
        tour: mdiRotate360,
        pesquisar: mdiMagnify,
        icone: mdiHomeCity,
        search: "",
        clientes: [],
        cidades: [],
        bairros: [],
        imoveis: [],
        headers_master: [
          { text: "ID", align: "start", sortable: true, value: "id" },
          { text: "Cliente", value: "id_cliente" },
          { text: "Descrição", value: "descricao" },
          { text: "Cidade", value: "id_cidade" },
          { text: "Bairro", value: "id_bairro" },
          { text: "Negócio", value: "tipo_negocio" },
          { text: "Tipo", value: "tipo_imovel" },
          { text: "Subtipo", value: "subtipo_imovel" },
          // { text: "Área Útil (m²)", value: "areautil", align: "right" },
          // { text: "Área Total (m²)", value: "areatotal", align: "right" },
          { text: "Valor", value: "valor", align: "right" },
          { text: "Ações", align: "center", value: "actions", sortable: false },
        ],
        headers_cliente: [
          { text: "ID", align: "start", sortable: true, value: "id" },
          { text: "Descrição", value: "descricao" },
          { text: "Cidade", value: "id_cidade" },
          { text: "Bairro", value: "id_bairro" },
          { text: "Negócio", value: "tipo_negocio" },
          { text: "Tipo", value: "tipo_imovel" },
          { text: "Subtipo", value: "subtipo_imovel" },
          { text: "Área Útil (m²)", value: "areautil", align: "right" },
          { text: "Área Total (m²)", value: "areatotal", align: "right" },
          { text: "Valor", value: "valor", align: "right" },
          { text: "Ações", align: "center", value: "actions", sortable: false },
        ],
        headers: [],
        dialog: false,
        dialogDelete: false,
        editedIndex: -1,
        editedItem: {
          id: "",
          id_cliente: 0,
          id_usuario: 0,
          descricao: "",
          tipo_negocio: 1,
          id_cidade: 0,
          id_bairro: 0,
          tipo_imovel: 1,
          subtipo_imovel: 1,
          valor: 0,
          suites: 0,
          quartos: 0,
          ambientes: 0,
          garagens: 0,
          areautil: 0,
          areatotal: 0,
          imagem: "",
          pasta_arquivos: "",
        },
        defaultItem: {
          id: "",
          id_cliente: 0,
          id_usuario: 0,
          descricao: "",
          tipo_negocio: 1,
          id_cidade: 0,
          id_bairro: 0,
          tipo_imovel: 1,
          subtipo_imovel: 1,
          valor: 0,
          suites: 0,
          quartos: 0,
          ambientes: 0,
          garagens: 0,
          areautil: 0.00,
          areatotal: 0.00,
          imagem: "",
          pasta_arquivos: "",
        },
        id_cliente_logado: "",
        isSelecting: false
      };
    },
  
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    formataValor(item) {
      var BRReal = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
      });

      return BRReal.format(item.valor);
    },

    formataArea(item) {
      if (item == null) {
        item = 0.00;
      }

      var BRArea = new Intl.NumberFormat('pt-BR');

      return BRArea.format(item);
    },

    buscaCliente(item) {
      var cliente = this.clientes.filter(d => d.id === item.id_cliente);
      return cliente[0].nome;
    },

    listaClientes() {
      svcClientes
        .listar()
        .then((resp) => {
          this.clientes = resp.data;
        })
        .catch((error) => {
          alert(error.response.data.mensagem);
          localStorage.clear();
          this.$router.push("/login");
        });
    },

    buscaCidade(item) {
      var cidade = this.cidades.filter(d => d.id === item.id_cidade);
      return cidade[0].nome + '-' + cidade[0].uf;
    },

    listaCidades() {
      svcCidades
        .listar()
        .then((resp) => {
          this.cidades = resp.data;
        })
        .catch((error) => {
          alert(error.response.data.mensagem);
          localStorage.clear();
          this.$router.push("/login");
        });
    },

    buscaBairro(item) {
      var bairro = this.bairros.filter(d => d.id === item.id_bairro);
      return bairro[0].nome;
    },

    listaBairros() {
      svcBairros
        .listar(0)
        .then((resp) => {
          this.bairros = resp.data;
        })
        .catch((error) => {
          alert(error.response.data.mensagem);
          localStorage.clear();
          this.$router.push("/login");
        });
    },

    buscaTipoNegocio(item) {
      return descTipoNegocio(item);
    },

    buscaTipoImovel(item) {
      return descTipoImovel(item);
    },

    buscaSubTipoImovel(item) {
      return descSubTipoImovel(item);
    },

    lista() {
      svcImoveis
        .listar(this.id_cliente_logado)
        .then((resp) => {
          this.imoveis = resp.data;
        })
        .catch((error) => {
          alert(error.response.data.mensagem);
          localStorage.clear();
          this.$router.push("/login");
        });
    },

    newItem() {
      this.$router.push("/imoveis/new");
    },

    editItem(item) {
      this.$router.push("/imoveis/edit/" + encriptar(item.id));
    },

    deleteItem(item) {
      this.editedIndex = this.imoveis.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      svcImoveis
        .excluir(this.editedItem.id)
        .then((resp) => {
          if (resp.status == 200) {
            this.lista();
          } else {
            alert("Erro excluindo um imóvel.");
          }
        })
        .catch((error) => {
          alert(error);
        });
      this.closeDelete();
    },

    VisualizarCapa(item) {
      // this.$router.push("/imoveis/capa/" + encriptar(item.id));
      const routeData = this.$router.resolve("/imoveis/capa/" + encriptar(item.id));
      window.open(routeData.href, '_blank');
    },

    VisualizarTour(item) {
      var cpfcnpj = "";

      svcClientes
        .pesquisar(item.id_cliente)
        .then((resp) => {
          cpfcnpj = resp.data.cpfcnpj;
          window.open(process.env.VUE_APP_URL_FILES + "/t/" + cpfcnpj + "/" + item.pasta_arquivos + "/app-files", '_blank');
        })
        .catch((error) => {
          alert(error.data.mensagem);
        });
    },
    
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },

  mounted() {
    this.id_cliente_logado = decriptar(localStorage.getItem("cdi"));
    if (decriptar(localStorage.getItem("udi")) == "0") {
      this.headers = this.headers_master;
    } else {
      this.headers = this.headers_cliente;
    }
    this.listaClientes();
    this.listaCidades();
    this.listaBairros();
    this.lista();
  },
};
</script>
