import { http } from './config'
import { encriptar } from '../config/cripto';

export default {
    listar: (id_cliente) => {
        return http.get(`/imoveis/listar/${encriptar(id_cliente)}`, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    },
    incluir: (imovel) => {
        const params = {
            imovel: imovel
        }
        return http.post('/imoveis/incluir', params, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    },
    alterar: (imovel) => {
        const params = {
            imovel: imovel
        }
        return http.put('/imoveis/alterar', params, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    },
    excluir: (id) => {
        return http.delete(`/imoveis/excluir/${encriptar(id)}`, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    },
    pesquisar: (id) => {
        return http.get(`/imoveis/pesquisar/${encriptar(id)}`, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    },
    pesquisarficha: (id) => {
        return http.get(`/imoveis/pesquisarficha/${encriptar(id)}`)
    },
    uploadzip: (cpfcnpj, pasta_arquivos, arquivo) => {
        const formData = new FormData();
        formData.append('arquivo', arquivo);
        const headers = { 'Content-Type': 'multipart/form-data' };
        return http.post(`/imoveis/uploadzip/${cpfcnpj}/${pasta_arquivos}`, formData, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    },
    uploadimage: (id, img) => {
        const formData = new FormData();
        formData.append('imagem', img);
        const headers = { 'Content-Type': 'multipart/form-data' };
        return http.post(`/imoveis/uploadimage/${encriptar(id)}`, formData, { headers: {'x-access-token': `${localStorage.getItem("tk")}`} })
    }
}