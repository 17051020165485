tipos_negocios = [
    { texto: "LANÇAMENTO",
      valor: 1,
    },
    { texto: "VENDA",
      valor: 2,
    },
    { texto: "LOCACAO",
      valor: 3,
    },
    { texto: "TEMPORADA",
      valor: 4,
    },
  ];

tipos_imoveis = [
    { texto: "RESIDENCIAL",
      valor: 1,
    },
    { texto: "COMERCIAL",
      valor: 2,
    },
    { texto: "NEGÓCIO/INVESTIMENTO",
      valor: 3,
    },
    { texto: "LAZER",
      valor: 4,
    },
    { texto: "TERRENO/ÁREA",
      valor: 5,
    },
    { texto: "RURAL",
      valor: 6,
    },
  ];

subtipos_residenciais = [
    { texto: "APARTAMENTO",
      valor: 1,
    },
    { texto: "CASA/SOBRADO",
      valor: 2,
    },
    { texto: "CASA/SOBRADO CONDOMÍNIO",
      valor: 3,
    },
    { texto: "COBERTURA/DUPLEX/TRIPLEX",
      valor: 4,
    },
    { texto: "KITNET/STUDIO/LOFT/FLAT/CHALÉ/PORÃO",
      valor: 5,
    },
    { texto: "SOBRELOJA",
      valor: 6,
    },
  ];

subtipos_comerciais = [
    { texto: "CASA/SOBRADO",
      valor: 1,
    },
    { texto: "SALA",
      valor: 2,
    },
    { texto: "SALA EM EDIFÍCIO",
      valor: 3,
    },
    { texto: "SALA EM SOBRELOJA",
      valor: 4,
    },
    { texto: "EDIFÍCIO COMERCIAL",
      valor: 5,
    },
    { texto: "LAJE COMERCIAL",
      valor: 6,
    },
    { texto: "LOJA",
      valor: 7,
    },
    { texto: "LOJA EM SHOPPING",
      valor: 8,
    },
    { texto: "SALAO",
      valor: 9,
    },
    { texto: "BARRACÃO",
      valor: 10,
    },
    { texto: "GALPÃO/ARMAZÉM",
      valor: 11,
    },
    { texto: "BOX",
      valor: 12,
    },
    { texto: "GARAGEM",
      valor: 13,
    },
  ];
  
subtipos_negocios = [
    { texto: "ALIMENTAÇÃO",
      valor: 1,
    },
    { texto: "BAR/RESTAURANTE",
      valor: 2,
    },
    { texto: "BELEZA/ESTÉTICA",
      valor: 3,
    },
    { texto: "COMÉRCIO",
      valor: 4,
    },
    { texto: "CONSTRUÇÃO",
      valor: 5,
    },
    { texto: "ENTRETENIMENTO",
      valor: 6,
    },
    { texto: "ESTACIONAMENTO",
      valor: 7,
    },
    { texto: "FRANQUIA",
      valor: 8,
    },
    { texto: "HOTELARIA/MOTEL",
      valor: 9,
    },
    { texto: "INDÚSTRIA",
      valor: 10,
    },
    { texto: "LAZER",
      valor: 11,
    },
    { texto: "LOGÍSTICA",
      valor: 12,
    },
    { texto: "LOJAS",
      valor: 13,
    },
    { texto: "LOJAS EM SHOPPING",
      valor: 14,
    },
    { texto: "MODA/VESTUÁRIO",
      valor: 15,
    },
    { texto: "NEGÓCIOS COM IMÓVEL",
      valor: 16,
    },
    { texto: "OUTROS NEGÓCIOS",
      valor: 17,
    },
    { texto: "PONTO COMERCIAL",
      valor: 18,
    },
    { texto: "POSTO DE COMBUSTÍVEL",
      valor: 19,
    },
    { texto: "SAÚDE",
      valor: 20,
    },
    { texto: "SERVIÇOS",
      valor: 21,
    },
    { texto: "STARTUP",
      valor: 22,
    },
  ];

subtipos_lazer = [
    { texto: "CHÁCARA PARA FESTA",
      valor: 1,
    },
    { texto: "IMÓVEL A BEIRA DE RIO/LAGO",
      valor: 2,
    },
    { texto: "PARQUE AQUÁTICO",
      valor: 3,
    },
    { texto: "SALÃO PARA EVENTOS",
      valor: 4,
    },
    { texto: "SALÃO PARA FESTA INFANTIL",
      valor: 5,
    },
  ];

subtipos_terrenos = [
    { texto: "TERRENO",
      valor: 1,
    },
    { texto: "TERRENO EM CONDOMÍNIO",
      valor: 2,
    },
    { texto: "TERRENO COMERCIAL",
      valor: 3,
    },
    { texto: "TERRENO INDUSTRIAL",
      valor: 4,
    },
    { texto: "ÁREA PARA LOTEAMENTO",
      valor: 5,
    },
  ];

subtipos_rurais = [
    { texto: "CHÁCARA",
      valor: 1,
    },
    { texto: "SÍTIO",
      valor: 2,
    },
    { texto: "FAZENDA",
      valor: 3,
    },
    { texto: "HARAS",
      valor: 4,
    },
  ];

