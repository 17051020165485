import "./consts.js"
import svcEmail from "../services/svcEmail";

export function SetarSubtiposImoveis(tipo_imovel) {
  var subtipos_imoveis = [];
  switch (tipo_imovel) {
    case 1:
      subtipos_imoveis = subtipos_residenciais;
      break;
    case 2:
      subtipos_imoveis = subtipos_comerciais;
      break;
    case 3:
      subtipos_imoveis = subtipos_negocios;
      break;
    case 4:
      subtipos_imoveis = subtipos_lazer;
      break;
    case 5:
      subtipos_imoveis = subtipos_terrenos;
      break;
    case 6:
      subtipos_imoveis = subtipos_rurais;
      break;
    default:
      subtipos_imoveis = subtipos_residenciais;
  }
  return subtipos_imoveis;
}

export function descTipoNegocio(imovel) {
  var tipo_negocio = tipos_negocios.filter(d => d.valor === imovel.tipo_negocio);
  return tipo_negocio[0].texto;
}

export function descTipoImovel(imovel) {
  var tipo_imovel = tipos_imoveis.filter(d => d.valor === imovel.tipo_imovel);
  return tipo_imovel[0].texto;
}

export function descSubTipoImovel(imovel) {
  var subtipo_imovel = SetarSubtiposImoveis(imovel.tipo_imovel).filter(d => d.valor === imovel.subtipo_imovel);
  return subtipo_imovel[0].texto;
}

export function geraStringAleatoria(tamanho) {
  var stringAleatoria = '';
  var caracteres = 'abcdefghijklmnopqrstuvwxyz0123456789';
  for (var i = 0; i < tamanho; i++) {
      stringAleatoria += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
  }
  return stringAleatoria;
}  

export function formataValor(item) {
  var BRReal = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return BRReal.format(item.valor);
}

export function formataArea(item) {
  if (item == null) {
    item = 0.00;
  }

  var BRArea = new Intl.NumberFormat('pt-BR');

  return BRArea.format(item);
}

export function enviaEmail(to, subject, body) {
  var params = {
    to: to.trim(),
    subject: subject.trim(),
    body: body.trim()
  };

  svcEmail
  .email(params)
  .then((resp) => {
    console.log(resp.data);
  })
  .catch((error) => {
    console.log(resp.data);
  });
}  
